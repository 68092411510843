/** include framework dependencies **/

@import './../../framework/_Factories';
@import './../../framework/_AbstractClasses';
@import './../../framework/_Constants';

/** end include dependencies **/

/** include sections **/

$guideTitle:#0f1d41;

@import './../../sections/general/header';
@import './../../sections/general/page_header';


/** end include sections **/

$memberTitleColor:#58585a;
$memberEntityColor:#4e5157;
$memberDescriptionColor:#4e5157;
$memberDescriptionBorder:#df313c;
$memberDescriptionBackground:#f3f3f3;

.members-list-container{
	margin-bottom: 100px;
	.members-list{
		li{
			.rhombus-holder{
				.icon-holder{
					@extend .main-rhombus;
					@include square(39px);
					.inner-rhombus{
						@include rectangle(34px, 36px);
						.members-icon{
							@include square(36px);
							@include Rotate(225deg);
							background:url(#{$homeUrl}small_member_icon.png) 10px 4px no-repeat;
						}
					}
				}
			}
			.member-info{
				margin-bottom: $fullMargin;
				h6{
					margin-top: 0;
					margin-bottom: 0;
					a{
						@include textDecorator($memberTitleColor, $latoregular, 20px, 0);
						@include LinkDecorationNone();
						line-height:25px;
					}
				}
				.entity{
					@include textDecorator($memberEntityColor, $latoregular, 14px, 0);
					line-height:18px;
				}
			}

			.hidden-description{
				opacity:0;
				.description{
					@include textDecorator($memberDescriptionColor, $latoregular, 14px, 0);
					position: absolute;
					line-height: 20px;
					border-top: 1px solid $memberDescriptionBorder;
					top:70px;
					left:-12px;
					background:$memberDescriptionBackground;
					padding:$halfPadd;
					&:before{
						@include triangle($memberDescriptionBorder, 25px, 13px, 'top', 'isosceles');
						display:inline-block;
						content:"";
						position:absolute;
						top:-12px;
						left:19px;
					}
					&:after{
						@include triangle($memberDescriptionBackground, 23px, 11px, 'top', 'isosceles');
						display:inline-block;
						content:"";
						position:absolute;
						top:-11px;
						left:20px;
					}

				}

			}
		}
	}
}